export default {
  apartmentStatistics: {
    priceWithTaxes: "Ostohinta",
    estimatedPrice: "Hinta-arvio",
    area: "Pinta-ala",
    totalRent: "Vuokratulo / kk",
    totalWater: "Vesitulo / kk",
    totalParking: "Autopaikkatulo / kk",
    totalOthers: "Muut tulot / kk",
    totalIncomes: "Kokonaistulo / kk",
    maintenanceCharge: "Hoitovastike",
    waterCharge: "Vesimaksu",
    loanPayments: "Yhtiölainojen maksuerät / kk",
    totalLoans: "Yhtiölainat yhteensä",
    currentMonthReceipts: "Kulut tässä kuussa",
    totalReceipts: "Kulut koko ajalta",
    concurrentContracts: "Päällekkäisten sopimusten määrä",
    invoiceCount: "Toistuvien laskujen määrä",
    roomCount: "Huoneiden määrä",
  },
};
