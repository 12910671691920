<template>
  <v-dialog v-model="dialog" max-width="1500px">
    <v-card>
      <v-card-title>{{
        !editMode ? "Luo uusi taulukko" : `Muokkaa taulukkoa ${currentHeader.name}`
      }}</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6" lg="5" xl="4">
            <v-form ref="headerForm">
              <v-text-field
                v-model="currentHeader.name"
                label="Taulukon tunnistenimi"
                :rules="validations.required"
                placeholder="Heikin oma taulukkonäkymä"
                outlined
                dense
              ></v-text-field>
            </v-form>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="6" lg="5" xl="4">
            <v-form ref="form">
              <h3 class="mb-1">
                {{
                  edit
                    ? `Muokkaa saraketta ${currentHeader.columns[editingIndex].text}`
                    : "Luo uusi sarake"
                }}
              </h3>

              <v-autocomplete
                label="Lisää valmis laskelma"
                :items="mappedDefaultHeaders"
                item-text="text"
                item-value="formula"
                outlined
                dense
                small-chips
                return-object
                @change="addDefaultHeader"
              ></v-autocomplete>

              <v-text-field
                v-model="currentColumn.text"
                label="Sarakkeen nimi"
                :rules="validations.required"
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                v-model="selectedField"
                label="Lisää muuttuja kaavaan"
                :items="mappedVariables"
                item-text="displayName"
                item-value="placeholder"
                small-chips
                outlined
                dense
                @change="addFieldToFormula"
              ></v-autocomplete>

              <v-textarea
                v-model="currentColumn.formula"
                label="Sarakkeen kaava"
                :rules="validations.required"
                persistent-hint
                v-bind:placeholder="'Kaavaan voi vapaasti kirjoittaa, mutta laskennat täytyy tehdä hakasulkeiden [[ ]] sisällä. Esim. Vuokra per neliö [[ {{Vuokratulo / kk}} / {{Pinta-ala}} ]] €'"
                hint="Valitse arvoja alla olevasta kentästä lisätäksesi kaavaan"
                outlined
                dense
              ></v-textarea>

              <v-text-field
                v-model.number="currentColumn.rounding"
                label="Pyöristys (desimaalien määrä)"
                type="number"
                min="0"
                v-prevent-paste
                v-only-numbers-and-zero
                outlined
                dense
                :rules="[validations.numberBetween({ min: 0, max: 3 })]"
              ></v-text-field>
            </v-form>
            <v-btn color="primary" medium @click="addOrSaveColumn" class="mb-1"
              >{{ edit ? "Tallenna" : "Lisää" }} sarake</v-btn
            >
          </v-col>

          <!-- Oikea palsta: Luodut headerit -->
          <v-col cols="12" md="6" lg="7" xl="8">
            <h3 class="mb-1">Sarakkeet</h3>
            <p v-if="sortedColumns.length === 0">Ei vielä luotuja sarakkeita</p>

            <v-list dense>
              <draggable
                v-if="currentHeader.columns.length > 0"
                v-model="currentHeader.columns"
                @end="updateColumnOrder"
                handle=".compact-list-item"
                :disabled="edit"
              >
                <v-list-item
                  v-for="(column, index) in currentHeader.columns"
                  :key="column.order"
                  class="compact-list-item"
                  :class="{ 'active-item': editingIndex === index }"
                  style="cursor: move"
                >
                  <v-list-item-content>
                    <v-list-item-title class="no-ellipsis">
                      {{ column.order }}. {{ column.text }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="no-ellipsis">
                      Pyöristys: {{ column.rounding }} desimaalia
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="no-ellipsis">
                      Kaava: {{ column.formula }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="edit ? cancelEditing() : editHeader(index)">
                      <v-icon small :color="edit && editingIndex === index ? 'warning' : ''">{{
                        edit && editingIndex === index ? "mdi-close" : "mdi-pencil"
                      }}</v-icon>
                    </v-btn>
                    <v-btn v-if="!edit" icon @click="removeColumnItem(index)">
                      <v-icon small color="error">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </draggable>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!edit" color="info" @click="saveHeader">{{
          editMode ? "Tallenna" : "Luo"
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validations from "@/validations";
import tableVariables from "@/configs/tableVariables";
import defaultTableHeaders from "@/configs/defaultTableHeaders";
import mixins from "@/mixins/mixins";
import { mapState, mapMutations, mapActions } from "vuex";
import draggable from "vuedraggable";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
    tableName: { type: String, default: "" },
  },

  components: {
    draggable,
  },

  data() {
    return {
      edit: false,
      selectedField: null,
      validations,
    };
  },

  computed: {
    ...mapState("tableHeader", ["currentHeader", "currentColumn", "editingIndex", "editMode"]),

    dialog: {
      get() {
        return this.$store.state.tableHeader.openTableHeaderDialog;
      },
      set(value) {
        this.setField({ field: "openTableHeaderDialog", val: value });
      },
    },

    sortedColumns() {
      return [...this.currentHeader.columns].sort((a, b) => a.order - b.order);
    },

    mappedVariables() {
      if (!this.tableName) return [];

      return Object.entries(tableVariables[this.tableName]).map(([key, displayName]) => ({
        key,
        displayName,
        placeholder: `{{${displayName}}}`,
      }));
    },

    mappedDefaultHeaders() {
      if (!this.tableName) return [];
      return defaultTableHeaders[this.tableName];
    },
  },

  watch: {
    dialog(value) {
      if (value) {
        if (!this.editMode) this.setCurrentHeader({ name: "", columns: [] });

        this.$nextTick(() => {
          this.resetForm();
        });
      } else {
        this.edit = false;
      }
    },
  },

  methods: {
    ...mapMutations("tableHeader", [
      "addCurrentColumn",
      "setCurrentColumn",
      "removeColumn",
      "replaceColumn",
      "setField",
      "setCurrentHeader",
    ]),
    ...mapActions("tableHeader", ["createTableHeader", "editTableHeader"]),

    updateColumnOrder() {
      this.currentHeader.columns.forEach((column, index) => {
        column.order = index + 1;
      });
    },

    addOrSaveColumn() {
      if (this.$refs.form.validate()) {
        if (this.editingIndex !== null) {
          this.replaceColumn();
          this.setField({ field: "editingIndex", val: null });
          this.edit = false;
        } else {
          this.addCurrentColumn();
          this.updateColumnOrder();
        }
        this.resetForm();
      }
    },

    editHeader(index) {
      this.edit = true;
      const originalIndex = this.currentHeader.columns.findIndex(
        (column) => column.order === this.sortedColumns[index].order
      );
      const column = this.currentHeader.columns[originalIndex];
      this.setCurrentColumn({ ...column });
      this.setField({ field: "editingIndex", val: originalIndex });
    },

    cancelEditing() {
      this.edit = false;
      this.setField({ field: "editingIndex", val: null });
      this.resetForm();
    },

    async saveHeader() {
      if (this.$refs.headerForm.validate()) {
        if (this.edit) return this.showPopup("Tallenna nykyinen muokattava sarake ensin", "error");
        if (this.currentHeader.columns.length === 0)
          return this.showPopup("Lisää ainakin yksi sarake", "error");

        try {
          if (this.editMode) {
            await this.editTableHeader({ tableName: this.tableName, edit: this.editMode });
          } else {
            await this.createTableHeader({ tableName: this.tableName, edit: this.editMode });
          }
          this.resetForm();
          this.dialog = false;
          const text = this.editMode ? "Taulukko tallennettu" : "Taulukko luotu";
          this.showPopup(text, "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      } else {
        this.showPopup("Tarkista pakolliset kentät", "error");
      }
    },

    removeColumnItem(index) {
      if (confirm("Vahvista sarakkeen poisto")) {
        const originalIndex = this.currentHeader.columns.findIndex(
          (column) => column.order === this.sortedColumns[index].order
        );
        this.removeColumn(originalIndex);
        this.updateColumnOrder();
      }
    },

    addFieldToFormula() {
      if (this.selectedField) {
        this.currentColumn.formula += `${this.selectedField} `;
      }
    },

    addDefaultHeader(obj) {
      if (obj) {
        this.currentColumn.text = obj.text;
        this.currentColumn.formula = obj.formula;
        this.currentColumn.rounding = obj.rounding;
      }
    },

    resetForm() {
      const newOrder = this.currentHeader.columns.length + 1;
      this.setCurrentColumn({ text: "", value: "", formula: "", rounding: 2, order: newOrder });
      if (this.$refs.form) this.$refs.form.resetValidation();
      if (this.$refs.headerForm) this.$refs.headerForm.resetValidation();
    },
  },
};
</script>

<style scoped>
.active-item {
  background-color: #e3f2fd !important;
}

.compact-list-item {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  margin-bottom: 2px;
}

.compact-list-item:hover {
  background-color: #f5f5f5;
}

.no-ellipsis {
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
}
</style>
