<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Kohteiden statistiikka</div>

    <!-- Loader -->
    <full-page-loader
      v-if="pageLoading"
      text="Ladataan statistiikkaa..."
      class="full-page-loader"
    ></full-page-loader>

    <v-card v-if="!pageLoading" class="mt-2">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="
            setField({ field: 'editMode', val: false });
            setField({ field: 'openTableHeaderDialog', val: true });
          "
          >Luo taulukko</v-btn
        >
      </v-card-title>

      <v-container fluid>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              label="Vuokrakohteen osoite, postinumero tai kaupunki"
              append-icon="mdi-magnify"
              single-line
              hide-details
              solo
              @keyup.enter="getDataFromApi()"
              @click:append="getDataFromApi()"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Select tableheaders -->
        <v-row class="mt-1" dense>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <select-table-headers :tableName="tableName"></select-table-headers>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        item-key="_id"
        item-value="id"
        :headers="headers"
        :items="tableData"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- Apartment address / Overview link -->
        <template #[`item.address`]="{ item }">
          <router-link
            style="text-decoration: none"
            :to="`/apartment/overview/${item.apartmentId}`"
          >
            <div class="font-weight-bold">
              <span
                >{{ item.address
                }}<span v-if="item.apartmentNumber">, {{ item.apartmentNumber }}</span>
              </span>
            </div>
          </router-link>
          <div>{{ item.zipCode }}, {{ item.city }}</div>
        </template>

        <template #no-data>
          <h2>Ei vuokrakohteita</h2>
        </template>
      </v-data-table>

      <!-- Create tableheaders -->
      <table-headers-dialog
        :val="openTableHeaderDialog"
        :tableName="tableName"
      ></table-headers-dialog>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import TableHeadersDialog from "@/components/TableHeaders/TableHeadersDialog.vue";
import SelectTableHeaders from "@/components/TableHeaders/SelectTableHeaders.vue";
import FullPageLoader from "@/components/FullPageLoader.vue";
import globalValues from "../../configs/globalValues";
import { getTableData } from "@/utils/tableHelpers";

export default {
  title: "Kohteiden statistiikka",

  components: {
    SelectTableHeaders,
    TableHeadersDialog,
    FullPageLoader,
  },

  mixins: [mixins],

  data: () => ({
    tableName: "apartmentStatistics",
    pageLoading: false,
    loading: true,
    options: {},
    search: "",
    globalValues,
  }),

  async created() {
    try {
      this.pageLoading = true;
      await this.$nextTick();
      await this.getTableHeaders(this.tableName);
      this.setUserTableHeader(this.tableName);
      this.pageLoading = false;
    } catch (err) {
      this.pageLoading = false;
      this.showPopup(err, "error");
    }
  },

  computed: {
    ...mapState("report", ["statisticsApartments", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
    ...mapState("tableHeader", ["editMode", "openTableHeaderDialog"]),
    ...mapState("statistics", ["stats"]),
    ...mapGetters("tableHeader", ["tableHeaders"]),

    headers() {
      return this.tableHeaders(this.tableName);
    },

    tableData() {
      return getTableData(this.statisticsApartments, this.headers, this.tableName);
    },
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("tableHeader", ["getTableHeaders"]),
    ...mapMutations("tableHeader", ["setField", "setUserTableHeader"]),
    ...mapActions("report", ["getApartmentStatistics"]),
    ...mapActions("statistics", ["getStats"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();
      let url = `/api/v1/reports/apartment-statistics?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;
      await this.getApartmentStatistics({ url });

      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
