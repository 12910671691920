export default {
  apartmentStatistics: [
    {
      text: "Arvonnousu",
      formula: "[[ (({{Hinta-arvio}} - {{Ostohinta}}) / {{Ostohinta}}) * 100 ]] %",
      rounding: 2,
    },
    {
      text: "Neliövuokra",
      formula: "[[ {{Vuokratulo / kk}} / {{Pinta-ala}} ]] €/m²",
      rounding: 2,
    },
    {
      text: "Bruttovuokratulo (€)",
      formula: "[[ {{Vuokratulo / kk}} * 12 ]] €",
      rounding: 2,
    },
    {
      text: "Bruttovuokratuotto (%)",
      formula: "[[ ({{Vuokratulo / kk}} * 12) / {{Ostohinta}} * 100 ]] %",
      rounding: 2,
    },
    {
      text: "Nettovuokratulo (€)",
      formula:
        "[[ ({{Kokonaistulo / kk}}  - {{Hoitovastike}} - {{Vesimaksu}} - {{Yhtiölainojen maksuerät / kk}}) * 12 ]] €",
      rounding: 2,
    },
    {
      text: "Sijoitetun pääoman tuotto (ROI)",
      formula:
        "[[ (({{Kokonaistulo / kk}} - {{Hoitovastike}} - {{Vesimaksu}} - {{Yhtiölainojen maksuerät / kk}}) * 12) / {{Ostohinta}} * 100 ]] %",
      rounding: 2,
    },
    {
      text: "Oman pääoman tuotto (ROE)",
      formula:
        "[[ (({{Kokonaistulo / kk}} - {{Hoitovastike}} - {{Vesimaksu}} - {{Yhtiölainojen maksuerät / kk}}) * 12) / ({{Ostohinta}} - {{Yhtiölainat yhteensä}}) * 100 ]] %",
      rounding: 2,
    },
    {
      text: "Omavaraisuusaste",
      formula: "[[ (1 - ({{Yhtiölainat yhteensä}} / {{Hinta-arvio}})) * 100 ]] %",
      rounding: 2,
    },
    {
      text: "Kassavirta",
      formula:
        "[[ {{Kokonaistulo / kk}} - ({{Hoitovastike}} + {{Vesimaksu}} + {{Yhtiölainojen maksuerät / kk}}) ]] €/kk",
      rounding: 2,
    },
    {
      text: "Kriittinen piste",
      formula: "[[ {{Hoitovastike}} + {{Vesimaksu}} + {{Yhtiölainojen maksuerät / kk}} ]] €/kk",
      rounding: 2,
    },
    {
      text: "Velkaantuneisuusaste",
      formula: "[[ {{Yhtiölainat yhteensä}} / {{Ostohinta}} * 100 ]] %",
      rounding: 2,
    },
    {
      text: "LTV (Loan-to-Value)",
      formula: "[[ {{Yhtiölainat yhteensä}} / {{Hinta-arvio}} * 100 ]] %",
      rounding: 2,
    },
    {
      text: "Velan tuotto (Debt Yield)",
      formula:
        "[[ (({{Kokonaistulo / kk}} - {{Hoitovastike}} - {{Vesimaksu}} - {{Yhtiölainojen maksuerät / kk}}) * 12) / {{Yhtiölainat yhteensä}} * 100 ]] %",
      rounding: 2,
    },
  ],
};
