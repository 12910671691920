<template>
  <div>
    <v-autocomplete
      :value="selectedHeader"
      :items="activeHeaders"
      clearable
      item-value="_id"
      item-text="name"
      label="Taulukot"
      outlined
      dense
      return-object
      hide-details
      @change="setSelectedHeader($event)"
      @click:clear="clearResults"
    >
      <!-- Muokkaa-nappi -->
      <template v-slot:append-outer v-if="selectedHeader && isUserCreatedTable">
        <v-icon @click="editHeader" class="cursor-pointer"> mdi-pencil </v-icon>
        <!-- Poista-nappi -->
        <v-icon @click="deleteHeader" class="cursor-pointer ml-2" color="error">
          mdi-delete
        </v-icon>
      </template>
    </v-autocomplete>

    <v-btn v-if="selectedHeader" class="mt-1" color="primary" small @click="saveAsDefaultHeader()"
      >Tallenna oletukseksi</v-btn
    >
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import mixins from "@/mixins/mixins";
export default {
  mixins: [mixins],

  props: {
    tableName: { type: String, default: "" },
  },

  computed: {
    ...mapState("tableHeader", ["activeHeaders", "selectedHeader", "editMode"]),
    ...mapState("account", ["currentUser"]),

    isUserCreatedTable() {
      const userId = this.$store.state?.account?.currentUser?._id || "";
      if (userId && this.selectedHeader && this.selectedHeader.createdByUser) {
        return String(userId) === String(this.selectedHeader.createdByUser);
      } else {
        return true;
      }
    },
  },

  methods: {
    ...mapActions("tableHeader", ["getTableHeaders", "saveDefaultHeader", "deleteTableHeader"]),
    ...mapMutations("tableHeader", ["setSelectedHeader", "setCurrentHeader", "setField"]),

    editHeader() {
      this.setCurrentHeader(this.selectedHeader);
      this.setField({ field: "editMode", val: true });
      this.setField({ field: "openTableHeaderDialog", val: true });
    },

    async deleteHeader() {
      if (confirm("Haluatko varmasti poistaa nykyisen taulukon?")) {
        try {
          await this.deleteTableHeader({
            tableName: this.tableName,
            headerId: this.selectedHeader._id,
          });
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    async saveAsDefaultHeader() {
      if (this.selectedHeader) {
        try {
          await this.saveDefaultHeader({
            tableName: this.tableName,
            headerId: this.selectedHeader._id,
          });
          this.showPopup("Tallennettu oletustaulukoksi", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    clearResults() {
      this.setField({ field: "selectedHeader", val: null });
    },
  },
};
</script>

<style></style>
